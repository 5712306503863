import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminDashboardComponent } from './pages/admin-dashboard/admin-dashboard.component';
import { AdminLogListComponent } from './pages/admin-log-list/admin-log-list.component';
import { AdminTenantListComponent } from './pages/admin-tenant-list/admin-tenant-list.component';
import { AdminTranslationListComponent } from './pages/admin-translation-list/admin-translation-list.component';
import { AdminUserListComponent } from './pages/admin-user-list/admin-user-list.component';
import { AdminComponent } from './pages/admin/admin.component';
import { CallbackComponent } from './pages/callback/callback.component';
import { HomeComponent } from './pages/home/home.component';
import { IdpUserComponent } from './pages/idp-user/idp-user.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './services/auth.guard';
import { AuthGuardLogin } from './services/auth.guard.login';
const routes: Routes = [

  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  { path: ':forsyning/login', component: LoginComponent, canActivate: [AuthGuardLogin] },
  { path: ':forsyning/home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: ':forsyning/callback', component: CallbackComponent },
  { path: ':forsyning/assignuser', component: IdpUserComponent },
  { path: ':forsyning/addcontract', component: IdpUserComponent },
  { path: ':forsyning/removecontract', component: IdpUserComponent },
  {
    path: 'admin', component: AdminComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'tenants', pathMatch: 'full' },
      { path: 'dashboard', component: AdminDashboardComponent },
      { path: 'translations', component: AdminTranslationListComponent },
      { path: 'tenants', component: AdminTenantListComponent },
      { path: 'users', component: AdminUserListComponent },
      { path: 'logs', component: AdminLogListComponent },
    ]
  },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuardLogin] },
  { path: 'callback', component: CallbackComponent },
  { path: 'assignuser', component: IdpUserComponent },
  {
    path: 'addcontract', component: IdpUserComponent // canActivate: [AuthGuard]
  },
  {
    path: 'removecontract', component: IdpUserComponent // canActivate: [AuthGuard]
  },

  {
    path: '**',
    redirectTo: 'login'
  }

  /*
   {
     path: '',
     redirectTo: 'update',
     pathMatch: 'full'
   },
   { path: 'update', component: UpdateComponent },
   {
     path: '**',
     redirectTo: 'update'
   }
   */
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
