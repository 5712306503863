import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommaPipe } from './comma.pipe';
import { FilterPipe } from './filter.pipe';
import { LowercaseFirstPipe } from './lowercase-first.pipe';
import { MomentPipe } from './moment.pipe';

@NgModule({
  imports: [],
  declarations: [
    MomentPipe,
    FilterPipe,
    LowercaseFirstPipe,
    CommaPipe,
  ],
  exports: [
    MomentPipe,
    FilterPipe,
    LowercaseFirstPipe,
    CommaPipe,
  ],
  providers: [
    MomentPipe,
    FilterPipe,
    LowercaseFirstPipe,
    CommaPipe,
  ],
})
export class PipesModule { }
