import { Component, OnInit } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';
import { AdminUserDetailComponent } from '../admin-user-detail/admin-user-detail.component';
import { Tenant } from '@cloud-and-service/portal-lib';
import { User } from '@cloud-and-service/portal-lib';

@Component({
  selector: 'app-admin-user-list',
  templateUrl: './admin-user-list.component.html',
  styleUrls: ['./admin-user-list.component.scss']
})
export class AdminUserListComponent implements OnInit {

  tenants: Tenant[] = [];
  users: User[] = [];

  currentTenant = {} as Tenant;

  constructor(
    private tenantService: TenantService,
    private userService: UserService,
    private modalService: BsModalService
  ) { }

  async ngOnInit(): Promise<void> {
    this.getTenants();
  }

  changeTenant(tenant: Tenant = {} as Tenant) {
    this.currentTenant = tenant;
    this.getUsers();
  }

  getTenants() {
    this.tenantService.getTenants()
      .then(
        (tenants: Tenant[]) => {
          this.tenants = tenants;
        }
      );
  }

  getUsers() {
    if (this.currentTenant.id) {
      this.userService.getUsers(this.currentTenant.id)
        .then(
          (users: User[]) => {
            this.users = users;
          }
        );
    }
  }

  userDetail(user: User = {} as User): void {
    const initialState: ModalOptions = {
      initialState: {
        user,
      },
      class: 'modal-lg'
    };
    this.modalService.show(AdminUserDetailComponent, initialState);
  }

}
