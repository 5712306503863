<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" *ngIf="!user.id">{{'USER_CREATE' | translation}}</h5>
        <h5 class="modal-title" *ngIf="user.id">{{'USER_EDIT' | translation}}</h5>
    </div>
    <div class="modal-body">

        <div class="form-floating mb-3">
            <input disabled type="text" class="form-control" id="id" [(ngModel)]="user.id">
            <label for="id">{{'ID' | translation}}</label>
        </div>

        <div class="form-floating mb-3">
            <input disabled type="search" class="form-control" id="mitIdKey" [(ngModel)]="user.mitIdKey">
            <label for="mitIdKey">{{'MITID' | translation}}</label>
        </div>

        <!-- <div class="form-floating mb-3">
            <input disabled type="search" class="form-control" id="nemIdKey" [(ngModel)]="user.nemIdKey">
            <label for="nemIdKey">{{'NEMID' | translation}}</label>
        </div> -->

        <div class="form-floating mb-3">
            <input disabled type="text" class="form-control" id="role" [(ngModel)]="user.role">
            <label for="role">{{'ROLE' | translation}}</label>
        </div>

        <hr>

        <div class="form-floating mb-3">
            <input disabled type="text" class="form-control" id="id" [(ngModel)]="user.id">
            <label for="id">{{'ID' | translation}}</label>
        </div>

        <div class="form-floating mb-3">
            <input disabled type="text" class="form-control" id="dateCreated" [value]="user.dateCreated | moment">
            <label for="dateCreated">{{'DATE_CREATED' | translation}}</label>
        </div>

        <div class="form-floating mb-3">
            <input disabled type="text" class="form-control" id="dateUpdated"  [value]="user.dateUpdated | moment">
            <label for="dateUpdated">{{'DATE_UPDATED' | translation}}</label>
        </div>
    </div>
    <div class="modal-footer">
        <button *ngIf="!user.id" type="button" class="btn btn-default" (click)="addUser()">
            {{'CREATE' | translation}}<i class="mx-2 fa-solid fa-paper-plane-top"></i>
        </button>
        <button *ngIf="user.id" type="button" class="btn btn-default" (click)="updateUser()">
            {{'SAVE' | translation}} <i class=" mx-2 fa-solid fa-floppy-disk"></i>
        </button>
    </div>
</div>