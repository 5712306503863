import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AcceptCookies, MainState } from '@cloud-and-service/portal-lib';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent {

  @Select(MainState.cookiesAccepted) cookiesAccepted$: Observable<boolean> | undefined;

  constructor(
    private store: Store
  ) { }

  acceptCookies() {
    this.store.dispatch(new AcceptCookies(true));
  }

}
