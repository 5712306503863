<div class="modal-content">
    <div class="modal-body">

        <p class="lead mb-2 me-3">{{'SELECT_LOCATION' | translation }}</p>
        @for(location of locations; track $index) {
        <span class="list-group-item list-group-item-action" aria-current="true"
            (click)="setLocation(location)">
            <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1 fw-normal w-100">{{location.description}}
                    <span class="float-end status-text">
                        {{location.status}}
                    </span>
                </h5>
            </div>
            <p class="mb-1">{{location.utilityName}}</p>
        </span>
        }
    </div>
</div>