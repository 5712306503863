<div class="content__header content__boxed overlapping">
    <div class="content__wrap pt-3 pb-4">
        <h1 class="page-title mb-2 mt-4">{{'TRANSLATIONS' | translation}}</h1>
        <p class="lead">
            {{'TRANSLATIONS_LEAD' | translation}}
        </p>
    </div>
</div>

<div class="content__boxed">
    <div class="content__wrap">
        <article class="d-md-flex gap-4">
            <div class="flex-fill">
                <section id="animations-tips" class="card mb-4">
                    <div class="card-body">

                        <div class="btn-group mx-1" dropdown>
                            <button id="button-basic" dropdownToggle type="button"
                                class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                                {{translation.culture}} <span class="caret"></span>
                            </button>
                            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu"
                                aria-labelledby="button-basic">
                                @for (culture of cultures; track $index) {
                                <li role="menuitem" (click)="changeCulture(culture.isoCode)">
                                    <a class="dropdown-item"> {{ culture.name }} ({{ culture.isoCode }})</a>
                                </li>
                                }
                            </ul>
                        </div>

                        <button type="button" class="btn btn-success float-end" (click)="getCurrentTranslations()">
                            <i class="fa-solid fa-arrows-rotate"></i>
                        </button>
                        <div style=" height: 75vh; overflow-y: scroll;">
                            @if (translations) {
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">{{'KEY' | translation}}</th>
                                        <th scope="col">{{'VALUE' | translation}}</th>
                                        <th scope="col">{{'CULTURE' | translation}}</th>
                                        <th scope="col">{{'ACTION' | translation}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input [(ngModel)]="translation.key" (keyup)="fixKey()"
                                                [disabled]="translation.tenantId ? 'true' : 'false'" type="text"
                                                class="form-control" placeholder="Key">
                                        </td>
                                        <td>
                                            <input [(ngModel)]="translation.value" type="text" class="form-control"
                                                placeholder="Value">
                                        </td>
                                        <td>
                                            <input disabled [(ngModel)]="translation.culture" type="text"
                                                class="form-control" placeholder="Culture">
                                        </td>
                                        <td>
                                            @if (!currentTenant.id && !translation.id) {
                                            <button type="button" class="btn btn-primary " (click)="addTranslation()">
                                                <i class="fa-solid fa-paper-plane-top"></i>
                                            </button>
                                            }
                                            @if (translation.id && translation.value) {
                                            <button type="button" class="btn btn-success "
                                                (click)="updateTranslation()">
                                                <i class="fa-solid fa-floppy-disk"></i>
                                            </button>
                                            }
                                            @if (currentTenant.id && !translation.id && translation.value) {
                                            <button type="button" class="btn btn-primary " (click)="addTranslation()">
                                                <i class="fa-solid fa-clone"></i>
                                            </button>
                                            }
                                        </td>
                                    </tr>
                                    @if ((translations | filter: ['key', 'value']: (translation.key ||
                                    translation.value)); as filteredTranslations) {
                                    <ng-container>
                                        @for (translation of filteredTranslations; track $index) {
                                        <tr>
                                            <td>{{translation.key}}</td>
                                            <td>{{translation.value}}</td>
                                            <td>{{translation.culture}}</td>
                                            <td>
                                                @if (translation.tenantId === currentTenant.id) {
                                                <button type="button" class="btn" (click)="edit(translation)">
                                                    <i class="fa-solid fa-pen"></i>
                                                </button>

                                                }
                                                @if (translation.tenantId !== currentTenant.id) {
                                                <button type="button" class="btn" (click)="clone(translation)">
                                                    <i class="fa-solid fa-clone"></i>
                                                </button>
                                                }
                                            </td>
                                        </tr>
                                        }
                                    </ng-container>
                                    }
                                </tbody>
                            </table>
                            }
                        </div>
                    </div>
                </section>
            </div>

        </article>
    </div>
</div>
