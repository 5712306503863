import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Environment, EnvironmentService, HttpService } from '@cloud-and-service/portal-lib';
import { Tenant } from '@cloud-and-service/portal-lib';
import { SetTenant } from '@cloud-and-service/portal-lib';
import { RouteInfo } from '../models/routeinfo';
import { UpdatePasswordRequest } from '../models/update-password-request';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  private readonly environment: Environment;

  constructor(
    private http: HttpService,
    private store: Store,
    environmentService: EnvironmentService
  ) {
    this.environment = environmentService.getEnvironment();
  }

  getCurrentTenant(): Promise<Tenant> {
    return this.http.get<Tenant>(`${this.environment.apiUrl}/tenant/current`)
      .then((tenant: Tenant) => {
        this.store.dispatch(new SetTenant(tenant));
        return tenant;
      });
  }

  getTenants(): Promise<Tenant[]> {
    return this.http.get<Tenant[]>(`${this.environment.apiUrl}/tenant/multiple`);
  }

  addTenant(tenant: Tenant): Promise<Tenant> {
    return this.http.post<Tenant, Tenant>(`${this.environment.apiUrl}/tenant/add`, tenant);
  }

  update(tenant: Tenant): Promise<Tenant> {
    return this.http.put<Tenant, Tenant>(`${this.environment.apiUrl}/tenant/update`, tenant);
  }

  updateTenantServicePassword(passwordRequest: UpdatePasswordRequest): Promise<void> {
    return this.http.put<any, UpdatePasswordRequest>(`${this.environment.apiUrl}/tenant/update/service/password`, passwordRequest);
  }

  updateTenantRestBasicAuthPassword(passwordRequest: UpdatePasswordRequest): Promise<void> {
    return this.http.put<any, UpdatePasswordRequest>(`${this.environment.apiUrl}/tenant/update/basic/password`, passwordRequest);
  }

  updateTenantRestOAuthSecret(passwordRequest: UpdatePasswordRequest): Promise<void> {
    return this.http.put<any, UpdatePasswordRequest>(`${this.environment.apiUrl}/tenant/update/o/secret`, passwordRequest);
  }

  // Tenant.Portal routines..
  getThemes(): Promise<string[]> {
    return this.http.get<string[]>(`${this.environment.apiUrl}/tenant/portalthemes`);
  }

  async uploadLogo(route: string, file: File): Promise<any> {
    const formData = new FormData();
    formData.append(file.name, file);
    formData.append('route', route);
    return this.http.post<any, any>(`${this.environment.apiUrl}/tenant/uploadlogo`, formData);
  }

  getRouteInfo(routeprefix: string): Promise<RouteInfo> {
    return this.http.get<RouteInfo>(`${this.environment.apiUrl}/tenant/routeinfo/${routeprefix}`);
  }

  routeExists(routeprefix: string): Promise<boolean> {
    return this.http.get<boolean>(`${this.environment.apiUrl}/tenant/routeexist/${routeprefix}`);
  }

  prefixExists(prefix: string): Promise<boolean> {
    return this.http.get<boolean>(`${this.environment.apiUrl}/tenant/prefixexist/${prefix}`);
  }

}
