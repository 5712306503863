import { Component, OnInit } from '@angular/core';
import { Tenant } from '@cloud-and-service/portal-lib';
import { Translation } from '@cloud-and-service/portal-lib';
import { cultures } from 'src/app/models/culture.mode';
import { TenantService } from 'src/app/services/tenant.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-admin-translation-list',
  templateUrl: './admin-translation-list.component.html',
  styleUrls: ['./admin-translation-list.component.scss']
})
export class AdminTranslationListComponent implements OnInit {

  globalTenant: Tenant = { name: 'Global', id: null } as Tenant;
  currentTenant: Tenant = this.globalTenant;
  tenants: Tenant[] = [];
  translations: Translation[] = [];
  culture: string = 'da-DK';
  cultures = cultures;
  translation: Translation = { culture: this.culture, tenantId: this.currentTenant.id } as Translation;

  constructor(
    private translationService: TranslationService,
    private tenantService: TenantService
  ) { }

  async ngOnInit(): Promise<void> {
    this.getTenants();
    this.getTranslations();
  }

  getTenants() {
    this.tenantService.getTenants()
      .then(
        (tenants: Tenant[]) => {
          this.tenants = tenants;
        }
      );
  }

  changeCulture(culture: string) {
    this.culture = culture;
    this.getTranslations();
  }

  changeTenant(tenant: Tenant) {
    this.currentTenant = tenant;
    this.getTranslations();
  }

  edit(translation: Translation) {
    this.translation = translation;
  }

  clone(translation: Translation) {
    const clone: Translation = {} as Translation;
    clone.key = translation.key;
    clone.value = translation.value;
    clone.culture = translation.culture;
    clone.tenantId = this.currentTenant.id;
    this.translation = clone;
  }

  getTranslations() {
    this.translationService.getTranslationsWithBlanks(this.currentTenant.id, this.culture)
      .then(
        (translations: Translation[]) => {
          this.translations = translations;
          this.translation = { culture: this.culture, tenantId: this.currentTenant.id } as Translation;
        }
      );
  }

  addTranslation() {
    if (!this.translation.key || !this.translation.value || !this.translation.culture) {
      return;
    }
    this.translation.culture = this.culture;
    this.translation.key = this.translation.key.toUpperCase();
    this.translation.tenantId = this.currentTenant.id;
    this.translationService.addTranslation(this.translation)
      .then(
        (translation: Translation) => {
          this.getTranslations();
        }
      );
  }

  updateTranslation() {
    if (!this.translation.id || !this.translation.key || !this.translation.value || !this.translation.culture) {
      return;
    }
    this.translation.culture = this.culture;
    this.translation.key = this.translation.key.toUpperCase();
    this.translation.tenantId = this.currentTenant.id;
    this.translationService.updateTranslation(this.translation)
      .then(
        (translation: Translation) => {
          this.getTranslations();
        }
      );
  }

  async getCurrentTranslations() {
    await this.translationService.getCurrentTranslations();
  }

  fixKey() {
    if (this.translation.key?.length) {
      this.translation.key = this.translation.key.replace(' ', "_");
      this.translation.key = this.translation.key.replace('-', "_");
      this.translation.key = this.translation.key.replace('.', "_");
      this.translation.key = this.translation.key.replace(/[^a-zA-Z0-9_]/g, "");
      this.translation.key = this.translation.key.toUpperCase();
    }
  }

}
