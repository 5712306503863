import { Component } from '@angular/core';
import { User } from '@cloud-and-service/portal-lib';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-admin-user-detail',
  templateUrl: './admin-user-detail.component.html',
  styleUrls: ['./admin-user-detail.component.scss']
})
export class AdminUserDetailComponent {

  user: User = {} as User;
  public onClose: Subject<any>;

  constructor(
    private userService: UserService,
    public bsModalRef: BsModalRef
  ) {
    this.onClose = new Subject();
  }

  addUser() {
    this.userService.registerUser(this.user)
      .then(
        () => {
          this.bsModalRef?.hide();
        }
      );
  }

  updateUser() {
    this.userService.update(this.user)
      .then(
        () => {
          this.bsModalRef?.hide();
        }
      );
  }

}
