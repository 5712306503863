<section id="content" class="content">
    <div class="content__boxed w-100 min-vh-100 d-flex flex-column align-items-center justify-content-center">
        <div *ngIf="showError" class="content__wrap">
            <div class="card shadow-lg">
                <div class="card-body">
                    <div class="alert alert-danger" role="alert">
                        <h1 class="h3">
                            <i class="fa-solid fa-circle-x"></i>
                            Login fejl
                        </h1>
                      </div>
                    <div class="text-center">
                        <p>{{ errormsg }}</p>
                    </div>
                    <div class="d-grid">
                        <button class="btn btn-primary btn-lg" (click)="toLogin()">Retur til login</button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="showSelectContract" class="card shadow-lg">
            <div class="card-body">
                <div class="text-center">
                    <h1 class="h3">Vælg forbrugssted</h1>
                </div>
                <table class="table table-hover">
                    <tbody>
                            <tr *ngFor="let location of locations; index as idx" style="cursor: pointer;" (click)="onUseContract(idx)">
                            <td>
                                {{ location.utilityName}}<br>
                                {{ location.description}}
                            </td>
                        </tr>
                    </tbody>
                 </table>
                 <div class="d-grid">
                    <button class="btn btn-primary buttom-0 endt-0" (click)="toLogin()">Tilbage</button>
                </div>
            </div>
        </div>
    </div>
</section>
