import { Injectable } from '@angular/core';

import { Environment, EnvironmentService, HttpService } from '@cloud-and-service/portal-lib';
import { IdpAuth } from '@cloud-and-service/portal-lib';
import { Location, LocationRemove } from '@cloud-and-service/portal-lib';
import { IdpContract } from '../models/idpContract';
import { RedirectType } from '../models/redirect-type';

@Injectable({
  providedIn: 'root'
})
export class CallBackService {
  environment: Environment;
  constructor(
    private httpService: HttpService,
    environmentService: EnvironmentService
  ) {
    this.environment = environmentService.getEnvironment()
  }

  async assignIdpUser(idpcontract: IdpContract): Promise<boolean> {
    idpcontract.origin = location.origin;
    idpcontract.redirecttype = RedirectType.assignIdpUser;
    return this.httpService.post<boolean, IdpContract>(`${this.environment.apiUrl}/idp/contract/add`, idpcontract);
  }

  async addContract(idpcontract: IdpContract): Promise<boolean> {
    idpcontract.origin = location.origin;
    idpcontract.redirecttype = RedirectType.addContract;
    return this.httpService.post<boolean, IdpContract>(`${this.environment.apiUrl}/idp/user/contract/add`, idpcontract);
  }

  async cleanUser(username: string, password: string): Promise<boolean> {
    return this.httpService.post<boolean, any>(`${this.environment.apiUrl}/idp/user/clean`, { username, password });
  }

  async getIdpUser(prefix: string, code: string): Promise<IdpAuth> {
    const origin = location.origin;
    return this.httpService.post<IdpAuth, any>(`${this.environment.apiUrl}/idp/user/get`, { origin, prefix, code });
  }

  async getIdpBroker(prefix: string, service: string, redirecttype: RedirectType): Promise<string> {
    const origin = location.origin;
    return this.httpService.post<string, any>(`${this.environment.apiUrl}/idp/broker/get`, { redirecttype, origin, prefix, service });
  }

  getIdpContracts(idpUser: IdpAuth): Promise<Location[]> {
    return this.httpService.post<Location[], IdpAuth>(`${this.environment.apiUrl}/idp/contract/all`, idpUser);
  }

  getIdpContractsByCode(idpcontract: IdpContract): Promise<LocationRemove[]> {
    idpcontract.origin = location.origin;
    idpcontract.redirecttype = RedirectType.removeContract;
    return this.httpService.post<LocationRemove[], any>(`${this.environment.apiUrl}/idp/contract/all/code`, idpcontract);
  }

  async removeContracts(contracts: LocationRemove[]): Promise<any>
  {
    return this.httpService.post<LocationRemove[], any>(`${this.environment.apiUrl}/idp/contract/delete`, contracts);
  }

}
