import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { AuthService, DynamicState, ENVIRONMENT, EnvironmentService, MainState, PortalLibModule, UtilityService } from '@cloud-and-service/portal-lib';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { UserIdleModule } from 'angular-user-idle';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CookiesComponent } from './components/cookies/cookies.component';
import { LocationListComponent } from './components/location-list/location-list.component';
import { MitIdAssociationComponent } from './components/mit-id-association/mit-id-association.component';
import { AdminDashboardComponent } from './pages/admin-dashboard/admin-dashboard.component';
import { AdminLogListComponent } from './pages/admin-log-list/admin-log-list.component';
import { AdminTenantDetailComponent } from './pages/admin-tenant-detail/admin-tenant-detail.component';
import { AdminTenantListComponent } from './pages/admin-tenant-list/admin-tenant-list.component';
import { AdminTranslationListComponent } from './pages/admin-translation-list/admin-translation-list.component';
import { AdminUserDetailComponent } from './pages/admin-user-detail/admin-user-detail.component';
import { AdminUserListComponent } from './pages/admin-user-list/admin-user-list.component';
import { AdminComponent } from './pages/admin/admin.component';
import { CallbackComponent } from './pages/callback/callback.component';
import { ConsumptionReadingComponent } from './pages/consumption-reading/consumption-reading.component';
import { HomeComponent } from './pages/home/home.component';
import { IdpUserComponent } from './pages/idp-user/idp-user.component';
import { LoginComponent } from './pages/login/login.component';
import { PipesModule } from './pipes/pipes.module';
import { appInitializer } from './services/app.initializer';
import { CallBackService } from './services/callback.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { ErrorInterceptor } from './services/error.interceptor';
import { JwtInterceptor } from './services/jwt-interceptor';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    AdminTenantListComponent,
    AdminUserListComponent,
    AdminComponent,
    AdminDashboardComponent,
    AdminTranslationListComponent,
    CallbackComponent,
    AdminTenantDetailComponent,
    AdminUserDetailComponent,
    AdminLogListComponent,
    CookiesComponent,
    IdpUserComponent,
    LocationListComponent,
    ConsumptionReadingComponent,
    MitIdAssociationComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    PipesModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    NgxsModule.forRoot([MainState, DynamicState]),
    NgxsStoragePluginModule.forRoot({ keys: [MainState, DynamicState] }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgHttpLoaderModule.forRoot(),
    ToastrModule.forRoot(),
    UserIdleModule.forRoot({ idle: 720, timeout: 180, ping: 10 }),
    ZXingScannerModule,
    PopoverModule.forRoot(),
    PortalLibModule,
  ],
  providers: [
    CallBackService,
    UtilityService,
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService, EnvironmentService, Router] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ENVIRONMENT, useValue: environment },
    {
      provide: ErrorHandler, useClass: ErrorHandlerService
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
