import { Injectable } from '@angular/core';
import { Environment, EnvironmentService, HttpService, Location, MainState, RelayService } from '@cloud-and-service/portal-lib';
import { Store } from '@ngxs/store';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { firstValueFrom } from 'rxjs';
import { LocationListComponent } from '../components/location-list/location-list.component';
import { CallBackService } from './callback.service';
import { MitIdAssociationComponent } from '../components/mit-id-association/mit-id-association.component';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  environment: Environment;
  constructor(
    private modalService: BsModalService,
    private callBackService: CallBackService,
    private http: HttpService,
    private store: Store,
    private relayService: RelayService,
    private httpService: HttpService,
    environmentService: EnvironmentService
  ) {
    this.environment = environmentService.getEnvironment();
  }

  public async presentLocations(fromlogin: boolean): Promise<void> {
    const locations: Location[] = await this.getLocations();

    const idpAuth = this.store.selectSnapshot(MainState.idpAuth);

    if (idpAuth) {
      await this.callBackService
        .getIdpContracts(idpAuth)
        .then(async (result: Location[]) => {
          result.forEach((location) => {
            if (locations.findIndex((x) => x.locationGuid == location.locationGuid) == -1) {
              locations.push(location);
            }
          });
        })
        .catch((error: any) => {
          //this.displayError(error);
        });
    }

    console.log(locations);

    if (locations.length === 1 && fromlogin) {
      await this.relayService.setLocation(locations[0]);
    } else {
      const initialState: ModalOptions = {
        initialState: {
          locations,
          fromLogin: fromlogin,
        },
        ignoreBackdropClick: true,
      };

      const bsModalRef = this.modalService.show(LocationListComponent, initialState);
      if (bsModalRef?.content?.onClose) {
        let location = await firstValueFrom(bsModalRef.content.onClose);
        if (location) {
          await this.relayService.setLocation(location);
        }
      }
    }
  }

  public async presentMitIDModal() {
    const initialState: ModalOptions = {
      ignoreBackdropClick: true,
    };
    this.modalService.show(MitIdAssociationComponent, initialState);
  }

  async getLocations(): Promise<Location[]> {
    return await this.httpService.get(`${this.environment.apiUrl}/Location/all`);
  }
}
