@if (tenant && tenant.portal) {
  <div class="modal-content">
    <div class="modal-header">
      @if (tenant.id) {
        <h5 class="modal-title"> {{ 'EDIT' | translation }} {{ tenant.name }} </h5>
      } @else {
        <h5 class="modal-title">{{ 'TENANT_CREATE' | translation }}</h5>
      }
    </div>
    <div class="modal-body">
      <div class="mb-3 gap-2 d-md-block">
        <button class="btn btn-primary" type="button" (click)="showPage('nav')">
          {{ 'TENANT_NAV' | translation }}
        </button>
        <span class="p-2"></span>
        <button class="btn btn-primary" type="button" (click)="showPage('portal')">
          {{ 'TENANT_PAGE' | translation }}
        </button>
      </div>

      @if (pageId === 'portal') {
        <div class="mb-3">
          <label>
            {{ 'URL_ROUTE' | translation }}
          </label>
          <input
            #portalroute
            type="text"
            class="form-control"
            [readonly]="tenant.id"
            [(ngModel)]="tenant.portal.route"
            (keyup)="validroute()"
            [ngClass]="{
              'is-invalid': !tenant.portal.route || tenant.portal.route.length === 0
            }"
            required />
        </div>

        <!--
            <div class="mb-3">
                <label>
                    {{ 'ChOOSE' | translation }} {{ 'THEME' | translation | lowercaseFirst }}
                </label>
                <select class="form-select" aria-label="Theme" [(ngModel)]="tenant.portal.uiTheme"
                    [compareWith]="compareThemes">
                    <option *ngFor="let theme of portalThemes" [ngValue]="theme">{{ theme }}</option>
                </select>
            </div>
            -->

        <div class="mb-3">
          <label>{{ 'LOGIN_HEADING' | translation }}</label>
          <input
            [disabled]="!tenant.portal.route || tenant.portal.route.length === 0"
            type="text"
            class="form-control"
            [(ngModel)]="tenant.portal.loginHeading" />
        </div>

        <label>{{ 'LOGO' | translation }}</label>
        <div class="mb-3 input-group">
          <input
            type="file"
            class="file-input"
            (change)="onFileUploadChange($event)"
            accept=".jpg,.png,.gif"
            #fileUpload
            [multiple]="false"
            [hidden]="true" />
          <input type="text" class="form-control" readonly [(ngModel)]="tenant.portal.logo" />

          <button class="btn btn-outline-success" (click)="fileUpload.click()" type="button">
            <i class="ms-1 fa-solid fa-upload"></i>
          </button>
        </div>

        <!--
            <div class="row g-2 mb-3">
                <div class="col-md">
                    <label>{{'LOGO' | translation}} {{ tenant.portal.logoInfo }}</label>
                    <input type="file" class="file-input" (change)="onFileUploadChange($event)" accept=".jpg,.png,.gif"
                        #fileUpload [multiple]="false" [hidden]="true">
                    <input type="text" class="form-control" readonly [(ngModel)]="tenant.portal.logo">
                </div>
                <div class="col-md-auto align-self-center">
                    <button class="btn btn-primary" (click)="fileUpload.click()">
                        {{ 'UPLOAD' | translation }}
                        <i class="ms-1 fa-solid fa-upload"></i>
                    </button>
                </div>
            </div>
            -->
      }

      @if (pageId === 'nav') {
        <div class="mb-3">
          <label>{{ 'PREFIX' | translation }}</label>
          <input
            type="text"
            class="form-control"
            #tenantprefix
            maxlength="4"
            (change)="validateFields()"
            [(ngModel)]="tenant.prefix"
            [ngClass]="{
              'is-invalid': !tenant.prefix || tenant.prefix.length < 4
            }"
            required />
        </div>

        <div class="mb-3">
          <label>{{ 'NAME' | translation }}</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="tenant.name"
            (change)="validateFields()"
            [ngClass]="{
              'is-invalid': !tenant.name || tenant.name.length === 0
            }"
            required />
        </div>

        <div class="mb-3">
          <label class="form-label">{{ 'LICENCE_ID' | translation }}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              #tenantprefix
              [(ngModel)]="tenant.licenceId"
              (change)="validateFields()"
              [ngClass]="{ 'is-invalid': !validateLicenceId(tenant.licenceId) }"
              required />
          </div>
          <div class="form-text">{{ 'LICENCE_ID_HELP' | translation }}</div>
        </div>
        <div>
          <tabset>
            <tab
              [heading]="'USE_SOAP_SERVICE' | translation"
              [active]="tenant.connectionType === 0"
              (selectTab)="tenant.connectionType = 0">
              <div class="mt-3">
                <div class="mb-3">
                  <label>{{ 'SERVICE_URL' | translation }}</label>
                  <input type="text" class="form-control" [(ngModel)]="tenant.serviceUrl" />
                </div>

                <div class="mb-3">
                  <label>{{ 'SERVICE_DOMAIN' | translation }}</label>
                  <input type="text" class="form-control" [(ngModel)]="tenant.serviceDomain" />
                </div>

                <div class="mb-3">
                  <label>{{ 'SERVICE_USERNAME' | translation }}</label>
                  <input type="search" class="form-control" [(ngModel)]="tenant.serviceUsername" />
                </div>

                <label>{{ 'SERVICE_PASSWORD' | translation }}</label>
                <div class="mb-3 input-group">
                  <input
                    autocomplete="new-password"
                    type="password"
                    class="form-control"
                    [(ngModel)]="tenant.servicePassword" />

                  <button class="btn btn-outline-success" (click)="updateTenantServicePassword()" type="button">
                    <i class="fa-solid fa-floppy-disk"></i>
                  </button>
                </div>
              </div>
            </tab>
            <tab
              [heading]="'USE_REST_BASIC' | translation"
              [active]="tenant.connectionType === 1"
              (selectTab)="tenant.connectionType = 1">
              <div class="mt-3">
                <div class="mb-3">
                  <label>{{ 'REST_BASIC_AUTH_URL' | translation }}</label>
                  <input type="text" class="form-control" [(ngModel)]="tenant.restBasicAuthUrl" />
                </div>

                <div class="mb-3">
                  <label>{{ 'REST_BASIC_AUTH_USERNAME' | translation }}</label>
                  <input type="search" class="form-control" [(ngModel)]="tenant.restBasicAuthUsername" />
                </div>

                <label>{{ 'REST_BASIC_AUTH_PASSWORD' | translation }}</label>
                <div class="mb-3 input-group">
                  <input
                    autocomplete="new-password"
                    type="password"
                    class="form-control"
                    [(ngModel)]="tenant.restBasicAuthPassword" />

                  <button class="btn btn-outline-success" (click)="updateTenantRestBasicAuthPassword()" type="button">
                    <i class="fa-solid fa-floppy-disk"></i>
                  </button>
                </div>
              </div>
            </tab>
            <tab
              [heading]="'USE_REST_OAUTH' | translation"
              [active]="tenant.connectionType === 2"
              (selectTab)="tenant.connectionType = 2">
              <div class="mt-3">
                <div class="mb-3">
                  <label>{{ 'REST_OAUTH_URL' | translation }}</label>
                  <input type="text" class="form-control" [(ngModel)]="tenant.restOAuthUrl" />
                </div>

                <div class="mb-3">
                  <label>{{ 'REST_OAUTH_ENDPOINT' | translation }}</label>
                  <input type="text" class="form-control" [(ngModel)]="tenant.restOAuthEndpoint" />
                </div>

                <div class="mb-3">
                  <label>{{ 'REST_OAUTH_SCOPE' | translation }}</label>
                  <input type="search" class="form-control" [(ngModel)]="tenant.restOAuthScope" />
                </div>

                <div class="mb-3">
                  <label>{{ 'REST_OAUTH_GRANT_TYPE' | translation }}</label>
                  <input type="search" class="form-control" [(ngModel)]="tenant.restOAuthGrantType" />
                </div>

                <div class="mb-3">
                  <label>{{ 'REST_OAUTH_CLIENT_ID' | translation }}</label>
                  <input type="search" class="form-control" [(ngModel)]="tenant.restOAuthClientId" />
                </div>

                <label>{{ 'REST_OAUTH_CLIENT_SECRET' | translation }}</label>
                <div class="mb-3 input-group">
                  <input
                    autocomplete="new-password"
                    type="password"
                    class="form-control"
                    [(ngModel)]="tenant.restOAuthClientSecret" />
                  <button class="btn btn-outline-success" (click)="updateTenantRestOAuthSecret()" type="button">
                    <i class="fa-solid fa-floppy-disk"></i>
                  </button>
                </div>
              </div>
            </tab>
            @if (environment.environment === 'local') {
              <tab heading="File" [active]="tenant.connectionType === 3" (selectTab)="tenant.connectionType = 3">
                <div class="mt-3">
                  <label>Tenant will only work with files on the server</label>
                </div>
              </tab>
            }
          </tabset>
        </div>

        <hr />

        <div class="mb-3">
          <label>{{ 'ID' | translation }}</label>
          <input disabled type="text" class="form-control" [(ngModel)]="tenant.id" />
        </div>

        <div class="row g-2">
          <div class="col-md">
            <div class="">
              <label>{{ 'DATE_CREATED' | translation }}</label>
              <input disabled type="text" class="form-control" [value]="tenant.dateCreated | moment" />
            </div>
          </div>
          <div class="col-md">
            <div class="">
              <label>{{ 'DATE_UPDATED' | translation }}</label>
              <input disabled type="text" class="form-control" [value]="tenant.dateUpdated | moment" />
            </div>
          </div>
        </div>
      }
    </div>

    <div class="modal-footer">
      @if (tenant.id) {
        <button [disabled]="!allowSave" type="button" class="btn btn-default" (click)="updateTenant()">
          {{ 'SAVE' | translation }}
          <i class="mx-2 fa-solid fa-floppy-disk"></i>
        </button>
      } @else {
        <button [disabled]="!allowSave" type="button" class="btn btn-default" (click)="addTenant()">
          {{ 'CREATE' | translation }}<i class="mx-2 fa-solid fa-paper-plane-top"></i>
        </button>
      }
    </div>
  </div>
}
