<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            {{'QUICK_CONSUMPTION_READING' | translation}}
        </h5>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="close()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
      <p>Hello warkness</p>
        <p [innerHTML]="'QUICK_CONSUMPTION_READING_HELP' | translation">

        </p>
        <div class="mb-3">
            <label class="form-label" for="form3Example4">{{'CONSUMPTION_CONTROL_ID' | translation}}
                <span class="text-danger" *ngIf="!consumption.controlId">
                    *{{'REQUIRED' | translation}}
                </span>
            </label>
            <input name="controlId" [(ngModel)]="consumption.controlId" type="text" class="form-control" required
                placeholder="{{'CONSUMPTION_CONTROL_ID' | translation}}" autofocus>
        </div>
        <div class="mb-3">
            <label class="form-label" for="form3Example4">{{'CONSUMPTION_CODE' | translation}}
                <span class="text-danger" *ngIf="!consumption.code">
                    *{{'REQUIRED' | translation}}
                </span>
            </label>
            <input name="code" [(ngModel)]="consumption.code" type="text" class="form-control" required
                placeholder="{{'CONSUMPTION_CODE' | translation}}" autofocus>
        </div>
        <div class="mb-3">
            <label class="form-label" for="form3Example4">{{'CONSUMPTION_USAGE' | translation}}
                <span class="text-danger" *ngIf="!consumption.usage">
                    *{{'REQUIRED' | translation}}
                </span>
            </label>
            <input name="code" [(ngModel)]="consumption.usage" type="text" class="form-control" required
                placeholder="{{'CONSUMPTION_USAGE' | translation}}" autofocus>
        </div>
        <p>
            {{'QUICK_CONSUMPTION_READING_HELP_2' | translation}}
        </p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary float-end" (click)="send()">{{'SEND' | translation}}</button>
    </div>
</div>
