import { Environment } from '@cloud-and-service/portal-lib';

export const environment: Environment = {
  mode: 'production',
  appVersion: require('../../package.json').version,
  environment: 'libra-dev',
  apiUrl: 'https://libraonlineapi-development.azurewebsites.net/api',
  storageUrl: 'https://libraonlinedevsa.blob.core.windows.net',
  loginPageSettings: {
    allowNemId: false,
    allowQuickConsumption: false,
  },
  hideLocationChanger: true,
  licenceRequired: false,
};
