import { Injectable } from '@angular/core';
import { Environment, EnvironmentService, HttpService } from '@cloud-and-service/portal-lib';

@Injectable({
  providedIn: 'root'
})
export class InfoService {
  environment: Environment;
  constructor(
    private http: HttpService,
    environmentService: EnvironmentService
  ) {
    this.environment = environmentService.getEnvironment();
  }

  async backendEnvironment(): Promise<any> {
    return await this.http.get<any>(`${this.environment.apiUrl}/info/environment`);
  }

  async backendVersion(): Promise<any> {
    return await this.http.get<any>(`${this.environment.apiUrl}/info/version`);
  }
}
