<div class="content__header content__boxed overlapping">
    <div class="content__wrap pt-3 pb-4">
        <h1 class="page-title mb-2 mt-4">{{'USERS' | translation}}</h1>
        <p class="lead">
            {{'USERS_LEAD' | translation}}
        </p>
    </div>
</div>

<div class="content__boxed">
    <div class="content__wrap">
        <article class="d-md-flex gap-4">
            <div class="flex-fill">
                <section id="animations-tips" class="card mb-4">
                    <div class="card-body">
                        <div class="btn-group mx-1" dropdown>
                            <button id="button-basic" dropdownToggle type="button"
                                class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                                <span *ngIf="!currentTenant.id">{{'ALL' | translation}}</span>
                                <span *ngIf="currentTenant.id">{{currentTenant.name}}</span>
                                <span class="caret"></span>
                            </button>
                            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu"
                                aria-labelledby="button-basic">
                                <li role="menuitem" (click)="changeTenant()">
                                    <a class="dropdown-item">{{'ALL' | translation}}</a>
                                </li>
                                <li role="menuitem" *ngFor="let tenant of tenants" (click)="changeTenant(tenant)">
                                    <a class="dropdown-item">{{tenant.name}}</a>
                                </li>
                            </ul>
                        </div>

                        <button *ngIf="!currentTenant.id" type="button" class="btn btn-success float-end"
                            (click)="userDetail()">
                            {{'ADD_USER' | translation}} <i class="ms-1 fa-solid fa-plus"></i>
                        </button>


                        <table *ngIf="users" class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">{{'ID' | translation}}</th>
                                    <th scope="col">{{'MITID' | translation}}</th>
                                    <!-- <th scope="col">{{'NEMID' | translation}}</th> -->
                                    <th scope="col">{{'ACTION' | translation}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let user of users">
                                    <td>{{user.id}}</td>
                                    <td>
                                         <input disabled class="form-check-input" type="checkbox" [value]="user.mitIdKey ? true : false">
                                    </td>
                                    <td>
                                        <input disabled class="form-check-input" type="checkbox" [value]="user.nemIdKey ? true : false">
                                   </td>
                                    <td>
                                        <button type="button" class="btn" (click)="userDetail(user)">
                                            <i class="fa-solid fa-pen"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        </article>
    </div>
</div>