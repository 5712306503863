import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetUser } from '@cloud-and-service/portal-lib';
import { Environment, EnvironmentService, HttpService } from '@cloud-and-service/portal-lib';
import { User } from '@cloud-and-service/portal-lib';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  environment: Environment;
  constructor(
    private http: HttpService,
    private store: Store,
    environmentService: EnvironmentService
  ) {
    this.environment = environmentService.getEnvironment();
  }

  async getCurrentUser(): Promise<User> {
    return await this.http.get<User>(`${this.environment.apiUrl}/user/current`)
      .then(
        (user: User) => {
          this.store.dispatch(new SetUser(user));
          return user;
        }
      );
  }

  getUsers(tenantId: string): Promise<User[]> {
    let url = `${this.environment.apiUrl}/user/multiple`;
    if (tenantId) {
      url += `?tenantId=${tenantId}`;
    }
    return this.http.get<User[]>(url);
  }

  registerUser(user: User): Promise<User> {
    return this.http.post<User, User>(`${this.environment.apiUrl}/auth/register`, user);
  }

  update(user: User): Promise<User> {
    return this.http.put<User, User>(`${this.environment.apiUrl}/user/update`, user);
  }
}
