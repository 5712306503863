<div #root class="root mn--max hd--expanded" (click)="toggleBackdrop()">
    <section id="content" class="content">
        <router-outlet></router-outlet>
        <!-- FOOTER -->
        <footer class="border-top mt-auto">
            <div class="content__boxed">
                <div class="content__wrap py-3 py-md-1 d-flex flex-column flex-md-row align-items-md-center">
                    <div class="text-nowrap mb-4 mb-md-0">Copyright &copy; 2021 <a href="#"
                            class="h6 text-decoration-none ms-1">My Company</a></div>
                    <nav class="nav flex-column gap-1 flex-md-row gap-md-3 ms-md-auto me-5"
                        style="row-gap: 0 !important;">
                        <a class="nav-link px-0" href="#">Policy Privacy</a>
                        <a class="nav-link px-0" href="#">Terms and conditions</a>
                        <a class="nav-link px-0" href="#">Contact Us</a>
                    </nav>
                </div>
            </div>
        </footer>
    </section>

    <header class="header">
        <div class="header__inner">
            <div class="header__brand">
                <div class="brand-wrap">
                    <div class="brand-title">{{supplier.name}}</div>
                </div>
            </div>
            <div class="header__content">
                <div class="header__content-start">
                    <button (click)="toggleMenu($event)" type="button"
                        class="nav-toggler header__btn btn btn-icon btn-sm">
                        <i class="fa-light fa-bars"></i>
                    </button>
                </div>
            </div>
        </div>
    </header>

    <nav id="mainnav-container" class="mainnav" (click)="prevent($event)">
        <div class="mainnav__inner">
            <div class="mainnav__top-content scrollable-content pb-5">
                <div class="mainnav__profile mt-3">
                    <div class="text-center py-2 collapsed">

                    </div>

                    <div class="mininav-content collapse d-mn-max">
                        <div class="d-grid">
                            <button class="d-block btn shadow-none p-2" data-bs-toggle="collapse"
                                data-bs-target="#usernav" aria-expanded="false" aria-controls="usernav">
                                <span class="d-flex justify-content-center align-items-center">
                                    <h6 class="mb-0 me-2">{{user.name}}</h6>
                                </span>
                                <small class="text-muted">{{user.email}}</small>
                            </button>
                        </div>
                    </div>
                </div>

                <h6 class="mainnav__caption mt-5 px-3 fw-bold">{{'ADMIN' | translation}}</h6>
                <ul class="mainnav__menu nav flex-column">

                    <!--
                    <li class="nav-item">
                        <a [routerLink]="['dashboard']" class="nav-link mininav-toggle collapsed">
                            <i class="fs-5 me-2 fa-thin fa-grid-horizontal w-25"></i>
                            <span class="nav-label mininav-content">{{'DASHBOARD' | translation}}</span>
                        </a>
                    </li>
                    -->
                    
                    <li class="nav-item">
                        <a [routerLink]="['tenants']" class="nav-link mininav-toggle collapsed">
                            <i class="fs-5 me-2 fa-thin fa-buildings w-25"></i>
                            <span class="nav-label mininav-content">{{'TENANTS' | translation}}</span>
                        </a>
                    </li>

                    <!--
                    <li class="nav-item">
                        <a [routerLink]="['users']" class="nav-link mininav-toggle collapsed">
                            <i class="fs-5 me-2 fa-thin fa-users w-25"></i>
                            <span class="nav-label mininav-content">{{'USERS' | translation}}</span>
                        </a>
                    </li>
                    -->

                    <li class="nav-item">
                        <a [routerLink]="['translations']" class="nav-link mininav-toggle collapsed">
                            <i class="fs-5 me-2 fa-thin fa-language w-25"></i>
                            <span class="nav-label mininav-content">{{'TRANSLATIONS' | translation}}</span>
                        </a>
                    </li>

                    <!--
                    <li class="nav-item">
                        <a [routerLink]="['logs']" class="nav-link mininav-toggle collapsed">
                            <i class="fs-5 me-2 fa-thin fa-triangle-exclamation w-25"></i>
                            <span class="nav-label mininav-content">{{'LOGS' | translation}}</span>
                        </a>
                    </li>
                    -->
                    
                    <li class="nav-item" (click)="logout()">
                        <a href="#" class="nav-link mininav-toggle collapsed">
                            <i class="fs-5 me-2 fa-thin fa-arrow-right-from-bracket w-25"></i>
                            <span class="nav-label mininav-content">{{'LOGOUT' | translation}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>