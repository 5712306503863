<div class="modal-header">
    <h5 class="modal-title">{{'ADD_MITID_LOCATION' | translation}}</h5>
</div>
<div class="modal-body">
    <div class="d-grid">
      <button id="add-mit-id-button" (click)="onAddContract()" class="btn btn-primary">{{'ADD_MITID_LOCATION' | translation}}</button>
    </div>
    <div class="d-grid mt-3">
      <button id="remove-mit-id-button" (click)="onRemoveContract()" class="btn btn-primary">{{'REMOVE_MITID_LOCATION' | translation}}</button>
    </div>
</div>
