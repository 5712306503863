import { Router } from '@angular/router';
import { AuthService, EnvironmentService } from '@cloud-and-service/portal-lib';

export function appInitializer(authService: AuthService, environmentService: EnvironmentService, router: Router) {
  return async () => {
    var environment = environmentService.getEnvironment();
    console.log(`===== App Initializer =====`);
    console.log(`Environment: ${environment.environment}`);
    console.log(`API Url: ${environment.apiUrl}`);
    console.log(`Storage Url: ${environment.storageUrl}`);
    console.log(`App Version: ${environment.appVersion}`);
    console.log(`===========================`);

    /*
        const url = window.location.href;
        if (url.includes('localhost')) {
            environment.apiUrl = environment.localApiUrl;
            environment.environment = "Local";
        }
        else if (url.includes('development')) {
            environment.apiUrl = environment.devApiUrl;
            environment.environment = "Development";
        }
        else {
            environment.apiUrl = environment.prodApiUrl;
            environment.environment = "Production";
        }
        if (url.includes('callback')) {
            return;
        }
        */
  };
}
