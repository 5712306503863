<div class="content__header content__boxed overlapping">
    <div class="content__wrap pt-3 pb-4">
        <h1 class="page-title mb-2 mt-4">{{'TENANTS_TITLE' | translation}}</h1>
        <p class="lead">
            {{'TENANTS_LEAD' | translation}}
        </p>
    </div>
</div>

<div class="content__boxed">
    <div class="content__wrap">
        <article class="d-md-flex gap-4">
            <div class="flex-fill">
                <section id="animations-tips" class="card mb-4">
                    <div class="card-body">

                        <button type="button" class="btn btn-danger float-end" (click)="throwError()">
                            {{'ERROR' | translation}} <i class="ms-1 fa-solid fa-plus"></i>
                        </button>

                        <!--
                        <table *ngIf="tenants" class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">{{'PREFIX' | translation}}</th>
                                    <th scope="col">{{'NAME' | translation}}</th>
                                    <th scope="col">{{'ACTION' | translation}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let tenant of tenants">
                                    <td>{{tenant.prefix}}</td>
                                    <td>{{tenant.name}}</td>
                                    <td>
                                        <button type="button" class="btn" (click)="tenantDetail(tenant)">
                                            <i class="fa-solid fa-pen"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    -->
                    </div>
                </section>
            </div>
        </article>
    </div>
</div>