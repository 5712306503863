import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { Environment, EnvironmentService } from "@cloud-and-service/portal-lib";
import { MainState } from "@cloud-and-service/portal-lib";
import { Observable } from "rxjs";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    environment: Environment;
    constructor(
        private store: Store,
        environmentService: EnvironmentService
    ) {
        this.environment = environmentService.getEnvironment();

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const auth = this.store.selectSnapshot(MainState.auth);
        const isLoggedIn = auth && auth.token;
        const isApiUrl = request.url.startsWith(this.environment.apiUrl);
        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: { authorization: `Bearer ${auth.token}` }
            });
        }

        return next.handle(request);
    }
}
