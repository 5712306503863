import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { AuthService, EnvironmentService, ModalService, UtilityService, TranslationService as PortalTranslationService } from '@cloud-and-service/portal-lib';
import { Role } from '@cloud-and-service/portal-lib';
import { User } from '@cloud-and-service/portal-lib';
import { MainState } from '@cloud-and-service/portal-lib';
import { Observable } from 'rxjs';
import { RedirectType } from 'src/app/models/redirect-type';
import { CallBackService } from 'src/app/services/callback.service';
import { LocationService } from 'src/app/services/location.service';
import { UserService } from 'src/app/services/user.service';
import { ConsumptionService } from 'src/app/services/consumption.service';
import { TranslationService } from 'src/app/services/translation.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username: string | null = null;
  password: string | null = null;

  loginPageSettings: any;

  @Select(MainState.logo) logo$: Observable<string> | undefined;

  @Select(MainState.title) title$: Observable<string> | undefined;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private translationService: TranslationService,
    private router: Router,
    private store: Store,
    private callBackService: CallBackService,
    private utilityService: UtilityService,
    private locationService: LocationService,
    public consumptionService: ConsumptionService,
    private modalService: ModalService,
    private translation: PortalTranslationService,
    environmentService: EnvironmentService
  ) {
    var environment = environmentService.getEnvironment();
    this.loginPageSettings = environment.loginPageSettings;
    console.log('LoginComponent.constructor()');
  }

  ngOnInit(): void {
    this.modalService.closeAllModals();
  }

  async login() {
    if (!this.username || !this.password) {
      return;
    }

    await this.authService.login(this.username, this.password);

    const auth = this.store.selectSnapshot(MainState.auth);
    if (!auth) {
      this.modalService.error(this.translation.getTranslation('ERROR_LOGIN'));
      return;
    }

    const _user: User = await this.userService.getCurrentUser();
    if (!this.store.selectSnapshot(MainState.user)) {
      this.modalService.error(this.translation.getTranslation('ERROR_CURRENT_USER'));
      return;
    }

    await this.translationService.getCurrentTranslations();
    if (!this.store.selectSnapshot(MainState.translations)?.length) {
      return;
    }

    if (_user.role == Role.user) {
      await this.locationService.presentLocations(true);

      if (!this.utilityService.isEmpty(auth.urlRoute)) {
        this.router.navigate([`${auth.urlRoute}/home`]);
      }
      else {
        this.router.navigate(['/']);
      }
    }
    else if (_user.role == Role.admin) {
      this.router.navigate(['admin']);
    }
  }

  async getIdpService(service: string) {
    const uri: any = await this.callBackService.getIdpBroker(this.store.selectSnapshot(MainState.routePrefix), service, RedirectType.login);
    window.open(uri.url, "_self");
  }
}
