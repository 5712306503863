import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Environment, EnvironmentService, HttpService, MainState, SetTranslations, Translation } from '@cloud-and-service/portal-lib';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  environment: Environment;

  constructor(
    private http: HttpService,
    private store: Store,
    environmentService: EnvironmentService
  ) {
    this.environment = environmentService.getEnvironment();
   }

  async addTranslation(translation: Translation): Promise<Translation> {
    let url = `${this.environment.apiUrl}/translation/add`;
    return this.http.post<Translation, Translation>(url, translation);
  }

  async updateTranslation(translation: Translation): Promise<Translation> {
    let url = `${this.environment.apiUrl}/translation/update`;
    return this.http.put<Translation, Translation>(url, translation);
  }

  async getTranslationsWithBlanks(tenantId: string | null, culture: string | null): Promise<Translation[]> {
    let queryParams = { tenantId: tenantId, culture: culture };
    let url = `${this.environment.apiUrl}/translation/list/withBlanks?${this.http.queryGenerator(queryParams)}`;
    return await this.http.get<Translation[]>(url);
  }

  async getCurrentTranslations(): Promise<Translation[]> {
    let tenant = this.store.selectSnapshot(MainState.tenant);
    let queryParams = { tenantId: tenant?.id };
    let url = `${this.environment.apiUrl}/translation?${this.http.queryGenerator(queryParams)}`;
    return await this.http.get<Translation[]>(url)
      .then(
        (translations: Translation[]) => {
          this.store.dispatch(new SetTranslations(translations));
          return translations;
        }
      );
  }
}
