<div id="overlay" *ngIf="(cookiesAccepted$ | async) === false"></div>

<div id="_dm-offcanvas" class="offcanvas offcanvas-bottom show " tabindex="-1" style="visibility: visible;"
    *ngIf="(cookiesAccepted$ | async) === false" aria-modal="true" role="dialog">

    <div class="boxed-layout w-100">

        <div style="max-height: 30vh; overflow: auto;">
            <!-- Offcanvas header -->
            <div class="offcanvas-header">
                <h3 class="offcanvas-title">{{'COOKIES' | translation}}</h3>
            </div>
            <!-- Offcanvas content -->
            <div class="offcanvas-body">
                <h5>{{'COOKIES_LEAD' | translation}}</h5>
                <p [innerHTML]="'COOKIES_MESSAGE' | translation">

                </p>


                <div class="row">
                    <!--
                    <div class="col-3 form-check form-switch">
                        <input class="form-check-input input-lg" type="checkbox" checked id="flexSwitchCheckDefault">
                        <label class="form-check-label" for="flexSwitchCheckDefault">
                            {{'COOKIES_NECESSARY' | translation}}
                        </label>
                    </div>
                    <div class="col-3 form-check form-switch">
                        <input class="form-check-input input-lg" type="checkbox" id="flexSwitchCheckDefault">
                        <label class="form-check-label" for="flexSwitchCheckDefault">
                            {{'COOKIES_STATISTICS' | translation}}
                        </label>
                    </div>
                    <div class="col-3 form-check form-switch">
                        <input class="form-check-input input-lg" type="checkbox" id="flexSwitchCheckDefault">
                        <label class="form-check-label" for="flexSwitchCheckDefault">
                            {{'COOKIES_PREFERENCES' | translation}}
                        </label>
                    </div>
                    -->
                    <button type="button" class="offset-9 col-3 btn btn-success mb-2" (click)="acceptCookies()">
                        {{'ACCEPT' |
                        translation}}</button>
                </div>


            </div>
        </div>

    </div>