import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { Observable, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';
import { TranslationService } from "@cloud-and-service/portal-lib";
import { MainState } from "@cloud-and-service/portal-lib";
import { AuthService } from "@cloud-and-service/portal-lib";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private store: Store,
        private toastrService: ToastrService,
        private translation: TranslationService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((err: any) => {

            if ([401, 403].includes(err.status)) {
                if (this.store.selectSnapshot(MainState.auth)) {
                    this.authService.logout();
                }
            }

            if ([429].includes(err.status)) {
                this.toastrService.warning(this.translation.getTranslation('BACK_OFF'));
            }

            const error = err?.error?.message || err?.error || err?.message || err?.statusText || 'Undefined client error';
            return throwError(() => error);
        }));
    }
}
