import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'comma'
})
export class CommaPipe implements PipeTransform {

  transform(value: number | string | null, ...args: unknown[]): string {
    if (value == undefined || value == null) {
      return '';
    }
    return value.toString().replace('.', ',');
  }

}
