import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], properties: string[], query: any): any[] {
    if (!query) {
      return items;
    }

    const result: any[] = [];
    if (typeof query === 'string' || query instanceof String) {
      query = query?.toLowerCase();
    }
    items.forEach((item: any) => {
      for (let i = 0; i < properties.length; i++) {
        const property: string = properties[i];
        let value: any = item[property];

        if (!value) {
          continue;
        }

        value = value.toString().toLowerCase();

        if (value.includes(query)) {
          result.push(item);
          break;
        }
      }
    });

    return result;
  }
}
