import { Injectable } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { firstValueFrom } from 'rxjs';

import { Confirm, ConfirmOption, TranslationService } from '@cloud-and-service/portal-lib';
import { Consumption } from '../models/consumption';
import { HttpService } from '@cloud-and-service/portal-lib';
import { ModalService } from '@cloud-and-service/portal-lib';
import { Environment } from '@cloud-and-service/portal-lib';
import { EnvironmentService } from '@cloud-and-service/portal-lib';
import { ConsumptionReadingComponent } from '../pages/consumption-reading/consumption-reading.component';

@Injectable({
  providedIn: 'root',
})
export class ConsumptionService {
  private readonly environment: Environment;
  constructor(
    private bsModalService: BsModalService,
    private modalService: ModalService,
    private http: HttpService,
    private translation: TranslationService,
    environmentService: EnvironmentService
  ) {
    this.environment = environmentService.getEnvironment();
  }

  async quickConsumptionReading(): Promise<any> {
    const initialState: ModalOptions = {
      ignoreBackdropClick: true,
    };
    const bsModalRef = this.bsModalService.show(ConsumptionReadingComponent, initialState);
    if (bsModalRef?.content?.onClose) {
      return await firstValueFrom(bsModalRef.content.onClose);
    }
  }

  async sendQuickConsumptionReading(consumption: Consumption): Promise<any> {
    const prefix = consumption.controlId.slice(0, 4);

    return this.http
      .post<any, any>(`${this.environment.apiUrl}/relay/dynamics?prefix=${prefix}`, consumption)
      .then((element: any) => {
        if (!element) {
          this.modalService.error(this.translation.getTranslation('EMPTY_RESPONSE'));
        } else {
          const confirm: Confirm = new Confirm();
          confirm.header = this.translation.getTranslation('QUICK_CONSUMPTION_READING');
          confirm.message = element.resultMessage;
          confirm.confirmOptions = [{ text: this.translation.getTranslation('CLOSE'), value: false } as ConfirmOption];
          this.modalService.confirm(confirm);
        }
      })
      .catch((error: any) => {
        this.modalService.error(error);
      });
  }
}
