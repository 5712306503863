export interface Culture {
  isoCode: string;
  name: string;
}

export const cultures: Culture[] = [
  { isoCode: 'da-DK', name: 'Danish' },
  { isoCode: 'en-US', name: 'English' },
  { isoCode: 'ss-SE', name: 'Swedish' },
  { isoCode: 'no-NO', name: 'Norwegian' },
  { isoCode: 'de-DE', name: 'German' },
  { isoCode: 'es-ES', name: 'Spanish' },
];
