import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Consumption } from 'src/app/models/consumption';
import { ConsumptionService } from 'src/app/services/consumption.service';


@Component({
  selector: 'app-consumption-reading',
  templateUrl: './consumption-reading.component.html',
  styleUrls: ['./consumption-reading.component.scss']
})
export class ConsumptionReadingComponent {

  public onClose: Subject<boolean>;
  consumption: Consumption = {} as Consumption;

  constructor(
    private consumptionService: ConsumptionService,
    public bsModalRef: BsModalRef
  ) {
    this.onClose = new Subject();
  }

  send() {
    if (!this.consumption.code || !this.consumption.controlId || !this.consumption.usage) {
      return;
    }
    this.consumptionService.sendQuickConsumptionReading(this.consumption);
    this.close();
  }

  close() {
    this.bsModalRef?.hide();
  }

}
