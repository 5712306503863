import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Confirm, ConfirmOption, MainState, ModalService, UtilityService } from '@cloud-and-service/portal-lib';
import { Store } from '@ngxs/store';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { RedirectType } from 'src/app/models/redirect-type';
import { CallBackService } from 'src/app/services/callback.service';

@Component({
  selector: 'app-idp-association',
  templateUrl: './mit-id-association.component.html',
  styleUrl: './mit-id-association.component.scss',
})
export class MitIdAssociationComponent {
  constructor(
    private bsModalRef: BsModalRef,
    private callbackService: CallBackService,
    private utility: UtilityService,
    private router: Router,
    private store: Store,
    private modalService: ModalService
  ) {}

  async onAddContract() {
    if (await this.logoffConfirm()) {
      this.bsModalRef?.hide();
      const auth = this.store.selectSnapshot(MainState.auth);
      if (!this.utility.isEmpty(auth?.urlRoute)) {
        this.router.navigate([`${auth?.urlRoute}/addcontract`]);
      } else {
        this.router.navigate(['addcontract']);
      }
    }
  }

  async onRemoveContract() {
    if (await this.logoffConfirm()) {
      this.bsModalRef?.hide();
      const auth = this.store.selectSnapshot(MainState.auth);
      if (!this.utility.isEmpty(auth?.urlRoute)) {
        this.router.navigate([`${auth?.urlRoute}/removecontract`]);
      } else {
        this.router.navigate(['removecontract']);
      }
    }
  }

  async getIdpService(service: string) {
    const uri: any = await this.callbackService.getIdpBroker(
      this.store.selectSnapshot(MainState.routePrefix),
      service,
      RedirectType.addContract
    );
    window.open(uri.url, '_self');
  }

  async logoffConfirm(): Promise<boolean> {
    const confirm: Confirm = new Confirm();
    confirm.header = 'Besked';
    confirm.message = 'Du bliver logget af ved udførelse af denne funktion. Fortsæt alligevel?';
    confirm.confirmOptions = [
      { text: 'Ja', value: true } as ConfirmOption,
      { text: 'Nej', value: false } as ConfirmOption,
    ];

    return await this.modalService.confirm(confirm);
  }
}
