import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { MainState } from "@cloud-and-service/portal-lib";
import { AuthService } from "@cloud-and-service/portal-lib";

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
    constructor(
        private router: Router,
        private store: Store,
        private authService: AuthService
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const auth = this.store.selectSnapshot(MainState.auth);
        if (auth) {
            var adminInHome = auth.role === 'Admin' && route.routeConfig?.path === 'home';
            var userInAdmin = auth.role === 'User' && route.routeConfig?.path === 'admin';
            var cannotActivate = adminInHome || userInAdmin;

            if (cannotActivate)
            {
                await this.authService.logout();
                var tenant = auth?.urlRoute;
                this.router.navigate([`${tenant}/login`]);
            }

            return !cannotActivate;
        } else {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }
}
