import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "@cloud-and-service/portal-lib";

@Injectable({ providedIn: 'root' })
export class AuthGuardLogin  {
    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        try {
            const auth = await this.authService.refreshToken();
            if (auth) {
                // Wrong auth for user <-> admin
                if (auth.role === 'Admin' && route.routeConfig?.path === 'home') {
                    await this.authService.logout();
                    return true;
                }

                // Wrong auth for admin <-> user
                if (auth.role === 'User' && route.routeConfig?.path === 'admin') {
                    await this.authService.logout();
                    return true;
                }

                if (auth.role === 'Admin' && auth.urlRoute === '') {
                    this.router.navigate(['admin']);
                    return false;
                }

                if (auth.urlRoute !== '') {
                    this.router.navigate([`${auth.urlRoute}/home`]);
                    return false;
                }

                await this.authService.logout();
                return true;
            }
            else {
                return true;
            }
        } catch (error) {
            return true;
        }
    }
}
