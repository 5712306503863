<div class="content__header content__boxed overlapping">
    <div class="content__wrap pt-3 pb-4">
        <h1 class="page-title mb-2 mt-4">{{'DASHBOARD' | translation}}</h1>
        <p class="lead">
            Oversigt
        </p>
    </div>
</div>

<div class="content__boxed">
    <div class="content__wrap">
        <div class="card mb-4">
            <div class="card-body">
                <h5 class="card-title">Info</h5>
                <p>Info message</p>
            </div>
        </div>

        <article class="d-md-flex gap-4">
            <div class="flex-fill">

                <div class="row">
                    <div class="col-6">
                        <div class="card mb-3 mb-xl-3">
                            <div class="d-flex align-items-stretch">
                                <div
                                    class="d-flex align-items-center justify-content-center flex-shrink-0 bg-danger px-4 text-white rounded-start">
                                    <i class="fa-thin fa-buildings fs-1"></i>
                                </div>
                                <div class="flex-grow-1 py-3 ms-3">
                                    <h5 class="h2 mb-0 text-danger">379</h5>
                                    <p class="mb-0">{{'TENANTS' | translation}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card mb-3 mb-xl-3">
                            <div class="d-flex align-items-stretch">
                                <div
                                    class="d-flex align-items-center justify-content-center flex-shrink-0 bg-danger px-4 text-white rounded-start">
                                    <i class="fa-thin fa-users fs-1"></i>
                                </div>
                                <div class="flex-grow-1 py-3 ms-3">
                                    <h5 class="h2 mb-0 text-danger">379</h5>
                                    <p class="mb-0">{{'USERS' | translation}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div class="w-md-160px w-xl-250px flex-shrink-0">
                <div class="card position-sticky" style="top: 1rem">
                    <div class="card-body">
                        <h5>Help!</h5>
                        <p>Help Stuff</p>
                    </div>
                </div>
            </div>
        </article>
    </div>
</div>