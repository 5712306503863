import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Inject, Injectable, Injector } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable()

export class ErrorHandlerService extends ErrorHandler {
    constructor(
        @Inject(Injector) private readonly injector: Injector
    ) {
        super();
    }

    override handleError(error: any) {

        // Check if it's an error from an HTTP response
        if (!(error instanceof HttpErrorResponse)) {
            error = error?.error || error?.message || error?.statusText || 'Undefined client error';
        }

        //this.toastrService.error(error);
        super.handleError(error);
    }

    private get toastrService(): ToastrService {
        return this.injector.get(ToastrService);
    }
}